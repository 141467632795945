<template>
    <div v-if="productDelivery"
         class="sm-product-delivery-information"
         @click="onClick">
        <p class="sm-product-delivery-information-inner">
            <span :class="productDelivery.color">
                {{ productDelivery.status }}
            </span>
            <span v-if="productDelivery.time" class="sm-product-delivery-information-seperator">|</span>
            <span v-if="productDelivery.time" class="sm-product-delivery-information-time">
                {{ productDelivery.time }}
            </span>
        </p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SmProductDeliveryInformation',

  props: {
    product: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    keyMapDeliveryTime: {
      type: String,
      required: false,
      default: "deliveryTime",
    }
  },

  computed: {
    productDelivery() {
      const deliveryTime = this.product[this.keyMapDeliveryTime];

      if (!deliveryTime || !this.product) {
        return null;
      }

      if (this.product.availableStock >= this.product.minPurchase) {
        return {
          color: 'text-success',
          status: 'Lieferbar',
          time: 'Lieferung in 1-3 Werktagen'
        }
      } else if (this.product.availableStock < this.product.minPurchase && this.product.restockTime > 100) {
        return {
          color: 'text-warning',
          status: 'Vorbestellen',
          time: 'Lieferzeit unklar'
        }
      } else if (this.product.availableStock < this.product.minPurchase && this.product.restockTime) {
        return {
          color: 'text-success',
          status: 'Lieferbar',
          time: `Lieferung in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else if (this.product.availableStock < this.product.minPurchase && !this.product.restockTime) {
        return {
          color: 'text-danger',
          status: 'Lieferzeit anfragen',
          time: null
        }
      } else {
        return null;
      }
    }
  },

  methods: {
    onClick() {
      this.$emit('click', this.product)
    }
  }
});
</script>

<style scoped>
.sm-product-delivery-information-seperator {
    display: inline-block;
    margin: 0 3px;
    color: var(--ion-color-secondary);
}
</style>